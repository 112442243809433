import React, { useEffect, useRef, useState } from 'react';
import axios from '../axios';
import { toast } from 'react-toastify';
import Loader from '../Components/Loader';
import Switch from "react-switch";

const ClientCallbackSetting = (props) => {
    const DivRef = useRef(null);
    const [loader, setLoader] = useState(false);
    const LoginClientId = JSON.parse(localStorage.getItem("ClientId"));
    const UserGroup = JSON.parse(localStorage.getItem("UserGroup"));

    const [CreateTicket, setCreateTicket] = useState({ Url: "", Method: "POST", Headers: "{}" });
    // const [FaultUpdate, setFaultUpdate] = useState({ Url: "", Method: "POST", Headers: "{}" });
    const [ChangeTicketStatus, setChangeTicketStatus] = useState({ Url: "", Method: "POST", Headers: "{}" });

    const [CreateTicketError, setCreateTicketError] = useState({ Url: "", Method: "", Headers: "" });
    // const [FaultUpdateError, setFaultUpdateError] = useState({ Url: "", Method: "", Headers: "" });
    const [ChangeTicketStatusError, setChangeTicketStatusError] = useState({ Url: "", Method: "", Headers: "" });

    const [ClientId, setClientId] = useState("");
    const [Active, setActive] = useState(false);
    const [MaintenanceList, setMaintenanceList] = useState([]);

    useEffect(() => {
        if (ClientId !== "") {
            setLoader(true);

            axios
                .get("client_callback_maintenance?ClientId=" + ClientId)
                .then((response) => {
                    setMaintenanceList(response.data.data); // Assuming API returns a list of maintenance items
                    setLoader(false);
                })
                .catch((error) => {
                    setLoader(false);
                });

        }

    }, [ClientId]);

    const HandleClose = () => {
        props.setCallbackSettingFlag(false);
    };

    const ClosePopup = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            HandleClose();
        }
    };

    useEffect(() => {
        if (parseInt(UserGroup.Id) === 3) {
            setClientId(LoginClientId);
        } else {
            setClientId(props.ClientId);
        }
    }, [UserGroup, props, LoginClientId]);

    useEffect(() => {
        if (ClientId !== "") {
            setLoader(true);
            axios
                .get("client_callback_setting/" + ClientId)
                .then((response) => {
                    setCreateTicket(response.data.data.CreateTicket ? response.data.data.CreateTicket : { Url: "", Method: "", Headers: "{}" });
                    // setFaultUpdate(response.data.data.FaultUpdate ? response.data.data.FaultUpdate : { Url: "", Method: "", Headers: "{}" });
                    setChangeTicketStatus(response.data.data.ChangeTicketStatus ? response.data.data.ChangeTicketStatus : { Url: "", Method: "", Headers: "{}" });
                    setActive(response.data.data.CallbackActiveFlag)
                    setLoader(false);
                })
                .catch((error) => {
                    setLoader(false);
                });
        }
    }, [ClientId]);

    // Validation function to check if headers are valid JSON
    const isValidJSON = (json) => {
        try {
            JSON.parse(json);
            return true;
        } catch (e) {
            return false;
        }
    };

    const HandleSubmit = (e) => {
        e.preventDefault();

        // Reset all error messages
        setCreateTicketError({ Url: "", Method: "", Headers: "" });
        // setFaultUpdateError({ Url: "", Method: "", Headers: "" });
        setChangeTicketStatusError({ Url: "", Method: "", Headers: "" });

        let isValid = true;

        // Validation for Create Ticket fields
        if (CreateTicket.Url === "") {
            setCreateTicketError(prevState => ({ ...prevState, Url: "Please enter your create Sigfox ticket callback URL." }));
            isValid = false;
        }
        if (CreateTicket.Method === "") {
            setCreateTicketError(prevState => ({ ...prevState, Method: "Please enter the method for the create Sigfox ticket." }));
            isValid = false;
        }
        if (CreateTicket.Headers === "" || !isValidJSON(CreateTicket.Headers)) {
            setCreateTicketError(prevState => ({ ...prevState, Headers: "Please enter valid JSON headers." }));
            isValid = false;
        }

        // Validation for Update Fault fields
        // if (FaultUpdate.Url === "") {
        //     setFaultUpdateError(prevState => ({ ...prevState, Url: "Please enter your fault update callback URL." }));
        //     isValid = false;
        // }
        // if (FaultUpdate.Method === "") {
        //     setFaultUpdateError(prevState => ({ ...prevState, Method: "Please enter the method for fault update." }));
        //     isValid = false;
        // }
        // if (FaultUpdate.Headers === "" || !isValidJSON(FaultUpdate.Headers)) {
        //     setFaultUpdateError(prevState => ({ ...prevState, Headers: "Please enter valid JSON headers." }));
        //     isValid = false;
        // }

        // Validation for Change Ticket Status fields
        if (ChangeTicketStatus.Url === "") {
            setChangeTicketStatusError(prevState => ({ ...prevState, Url: "Please enter your change ticket status callback URL." }));
            isValid = false;
        }
        if (ChangeTicketStatus.Method === "") {
            setChangeTicketStatusError(prevState => ({ ...prevState, Method: "Please enter the method for changing ticket status." }));
            isValid = false;
        }
        if (ChangeTicketStatus.Headers === "" || !isValidJSON(ChangeTicketStatus.Headers)) {
            setChangeTicketStatusError(prevState => ({ ...prevState, Headers: "Please enter valid JSON headers." }));
            isValid = false;
        }

        if (isValid && ClientId !== "") {
            setLoader(true);
            axios
                .put("update_client_callback/" + ClientId, {
                    CreateTicket,
                    // FaultUpdate,
                    ChangeTicketStatus,
                    CallbackActiveFlag: Active,
                    MaintenanceData: MaintenanceList
                })
                .then((response) => {
                    toast.success(response.data.status.message);
                    props.setCallbackSettingFlag(false);
                    setLoader(false);
                })
                .catch((error) => {
                    setLoader(false);
                });

        }
    };

    return (
        <>
            {loader ? <Loader /> : null}
            <div
                className='popup-background'
                style={{ zIndex: "99999999", height: "107vh" }}
                onClick={(e) => {
                    ClosePopup(e);
                }}
            >
                <div
                    className="card"
                    style={{
                        width: "70%",
                        height: "fit-content",
                        margin: "auto",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",
                        maxHeight: '90vh'
                    }}
                    ref={DivRef}
                >
                    <div className="card-body horizontal-scroll mt-4">
                        <i
                            className="bx bx-x"
                            title="Close"
                            style={{
                                position: 'absolute',
                                right: '20px',
                                top: '5px',
                                cursor: 'pointer',
                                fontSize: '23px',
                                fontWeight: 'bold'
                            }}
                            onClick={() => HandleClose()}
                        ></i>
                        <div className='row pb-2 mb-3' style={{ borderBottom: "1px solid #bcbbcc" }}>
                            <div className='col-sm-4'>

                            </div>
                            <div className='col-sm-4'>
                                <h4 className="" style={{ textAlign: "center" }}>
                                    <i className="bx bx-cog mx-2" style={{ fontSize: "29px" }}></i>Callback Setting
                                </h4>
                            </div>
                            <div className='col-sm-4 d-flex justify-content-end align-items-center'>
                                <label>Active</label>
                                &nbsp;
                                <Switch
                                    className="login-switch m-0"
                                    onColor={"#136dfa"}
                                    height={25}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onChange={() => {
                                        setActive(!Active)
                                    }}
                                    checked={Active}
                                />
                            </div>
                        </div>

                        <form onSubmit={HandleSubmit}>
                            <div className="row">

                                {/* Block for Create Ticket URL */}
                                <div className="col-sm-6">
                                    <div className="card p-3" style={{ border: "1px solid #e9e9e9" }}>
                                        <h5 style={{ textAlign: "center" }}>Create Sigfox Ticket Callback</h5>
                                        <label className="form-label">Callback URL</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={CreateTicket.Url}
                                            onChange={(e) => setCreateTicket(prevState => ({ ...prevState, Url: e.target.value }))}
                                        />
                                        <p className="text-danger mt-1">{CreateTicketError.Url}</p>

                                        <label className="form-label">Method</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={CreateTicket.Method}
                                            onChange={(e) => setCreateTicket(prevState => ({ ...prevState, Method: e.target.value }))}
                                        />
                                        <p className="text-danger mt-1">{CreateTicketError.Method}</p>

                                        <label className="form-label">Headers (JSON format)</label>
                                        <textarea
                                            className="form-control"
                                            value={CreateTicket.Headers}
                                            onChange={(e) => setCreateTicket(prevState => ({ ...prevState, Headers: e.target.value }))}
                                        />
                                        <p className="text-danger mt-1">{CreateTicketError.Headers}</p>
                                    </div>
                                </div>

                                {/* Block for Update Fault URL */}
                                {/* <div className="card col-4 block gap-1 ">
                                    <h5 style={{ textAlign: "center" }}>Fault Update Callback</h5>
                                    <label className="form-label">Callback URL</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={FaultUpdate.Url}
                                        onChange={(e) => setFaultUpdate(prevState => ({ ...prevState, Url: e.target.value }))}
                                    />
                                    <p className="text-danger mt-1">{FaultUpdateError.Url}</p>

                                    <label className="form-label">Method</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={FaultUpdate.Method}
                                        onChange={(e) => setFaultUpdate(prevState => ({ ...prevState, Method: e.target.value }))}
                                    />
                                    <p className="text-danger mt-1">{FaultUpdateError.Method}</p>

                                    <label className="form-label">Headers (JSON format)</label>
                                    <textarea
                                        className="form-control"
                                        value={FaultUpdate.Headers}
                                        onChange={(e) => setFaultUpdate(prevState => ({ ...prevState, Headers: e.target.value }))}
                                    />
                                    <p className="text-danger mt-1">{FaultUpdateError.Headers}</p>
                                </div> */}

                                {/* Block for Change Ticket Status URL */}
                                <div className="col-sm-6 ">
                                    <div className="card p-3" style={{ border: "1px solid #e9e9e9" }}>
                                        <h5 style={{ textAlign: "center" }}>Change Ticket Status Callback</h5>
                                        <label className="form-label">Callback URL</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={ChangeTicketStatus.Url}
                                            onChange={(e) => setChangeTicketStatus(prevState => ({ ...prevState, Url: e.target.value }))}
                                        />
                                        <p className="text-danger mt-1">{ChangeTicketStatusError.Url}</p>

                                        <label className="form-label">Method</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={ChangeTicketStatus.Method}
                                            onChange={(e) => setChangeTicketStatus(prevState => ({ ...prevState, Method: e.target.value }))}
                                        />
                                        <p className="text-danger mt-1">{ChangeTicketStatusError.Method}</p>

                                        <label className="form-label">Headers (JSON format)</label>
                                        <textarea
                                            className="form-control"
                                            value={ChangeTicketStatus.Headers}
                                            onChange={(e) => setChangeTicketStatus(prevState => ({ ...prevState, Headers: e.target.value }))}
                                        />
                                        <p className="text-danger mt-1">{ChangeTicketStatusError.Headers}</p>
                                    </div>
                                </div>

                                {/* Maintenance List with Switch Button */}
                                <div className="row mt-4 px-5">
                                    <table
                                        className="table mb-0 table-striped text-center"
                                        style={{ verticalAlign: "middle" }}
                                    >
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th style={{ textAlign: "left" }}>Maintenance</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {MaintenanceList.length > 0 ?
                                                MaintenanceList.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td style={{ textAlign: "left" }}>
                                                                {item.Maintenance}
                                                            </td>
                                                            <td>
                                                                <Switch
                                                                    onColor={"#136dfa"}
                                                                    height={20}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onChange={(e) => {
                                                                        const TempArr = [...MaintenanceList];
                                                                        TempArr[index].Active = parseInt(TempArr[index].Active) === 0 || TempArr[index].Active === null ? 1 : 0;
                                                                        setMaintenanceList(TempArr);
                                                                    }}
                                                                    checked={parseInt(item.Active) === 1}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                }) :
                                                <tr>
                                                    <td
                                                        colSpan={5}
                                                        style={{ textAlign: "center" }}>
                                                        <b>No Record Found</b>
                                                    </td>
                                                </tr>}
                                        </tbody>
                                    </table>


                                </div>

                                <div className="d-grid mt-4 " style={{ justifyContent: "center" }}>

                                    <button className="btn btn-primary" type="submit">Save</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientCallbackSetting;
