import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import Map from "../DashboardMap/Map";
import BasestationDetails from '../DashboardMap/BasestationDetails'
import OpenTickets from '../OpenTickets';
import BsDownAlert from "./BsDownAlert";
import { ToastContainer, toast } from "react-toastify";
import CronStatus from "./CronStatus";
import ImpactedDeviceDownAlert from "./ImpactedDeviceDownAlert";
import BaseStationSpecificAlert from "./BaseStationSpecificAlert";
import ClientPage from "./ClientDetails";
import CountryPage from "./CountryDetails";

const Home = () => {
  var UserGroup = JSON.parse(localStorage.getItem("UserGroup"));
  const [loader, setLoader] = useState(false);
  const [SmallLoader, setSmallLoader] = useState(false);

  const [ClientWiseBaseStationData, setClientWiseBaseStationData] = useState([]);
  const LoginClientId = localStorage.getItem("ClientId");
  const LoginClientCountryId = localStorage.getItem("ClientCountryId");
  const [ClientId, setClientId] = useState(LoginClientId);

  const [CountrywiseBaseStationData, setCountrywiseBaseStationData] = useState([]);
  const [ClientCountryId, setClientCountryId] = useState(LoginClientCountryId);

  const [BitComSelectedState, setBitComSelectedState] = useState("");

  const [SitewiseBaseStationData, setSitewiseBaseStationData] = useState([]);
  const [ClientSearch, setClientSearch] = useState("");
  const [CountrySearch, setCountrySearch] = useState("");
  const [StatusSearch, setStatusSearch] = useState("");
  const [Count, setCount] = useState(0);
  const [SiteCount, setSiteCount] = useState(0);
  const [ClientName, setClientName] = useState("");
  const [CountryName, setCountryName] = useState("");
  const [SelectedBaseStation, setSelectedBaseStation] = useState("");
  const [Basestations, setBasestations] = useState([]);

  const [BasestationsTestData, setBasestationsTestData] = useState([]);
  const [BasestationsTestName, setBasestationsTestName] = useState([]);
  const [SelectedState, setSelectedState] = useState("")
  const [SearchBaseStation, setSearchBaseStation] = useState("")
  const [ShowAll, setShowAll] = useState(false)
  const [ClientTicketFlag, setClientTicketFlag] = useState(false)
  const [CountryTicketFlag, setCountryTicketFlag] = useState(false)

  const [SiteId, setSiteId] = useState("")
  const [SiteName, setSiteName] = useState("")
  const [TotalCounts, setTotalCounts] = useState({})
  const [TotalBaseStationFlag, setTotalBaseStationFlag] = useState(false)

  const [TicketsModuleAccess, setTicketsModuleAccess] = useState(false);
  const Identify = "Site";

  const [BsDownAlertFlag, setBsDownAlertFlag] = useState(false);
  const [ImpactedDeviceDownAlertFlag, setImpactedDeviceDownAlertFlag] = useState(false);
  const [ImpactedDeviceDownAlertData, setImpactedDeviceDownAlertData] = useState([]);

  const [BaseStationSpecificFlag, setBaseStationSpecificFlag] = useState(false);
  const [BaseStationSpecificData, setBaseStationSpecificData] = useState([]);


  const [AlertModuleData, setAlertModuleData] = useState([]);
  const [AlertBadgeCount, setAlertBadgeCount] = useState(0);

  const [CronStatusData, setCronStatusData] = useState({});
  const [AllCountries, setAllCountries] = useState([]);
  const [CronStatusClientId, setCronStatusClientId] = useState("");
  const [CronStatusClientCountryId, setCronStatusClientCountryId] = useState("");

  const [SelectedCronStatusPage, setSelectedCronStatusPage] = useState("");

  const [RegionList, setRegionList] = useState([])
  const [Region, setRegion] = useState("")

  useEffect(() => {
    if (parseInt(ClientCountryId) > 0) {
      setLoader(true);
      axios.get("masters?ClientCountryId=" + ClientCountryId)
        .then((response) => {
          setRegionList(response.data.data.SigfoxSiteRegions)
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
        })
    }

  }, [ClientCountryId])
  useEffect(() => {
    if (CronStatusClientId !== "") {
      setLoader(true)
      axios.get("get_clients")
        .then((response) => {
          var ClientData = response.data.data;
          for (var i = 0; i < ClientData.length; i++) {
            if (parseInt(ClientData[i].Id) === parseInt(CronStatusClientId)) {
              setAllCountries(ClientData[i].countries)
            }
          }

          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
        })
    }
  }, [CronStatusClientId])

  useEffect(() => {
    if (CronStatusClientId !== "" && CronStatusClientCountryId !== "") {
      setCronStatusData({})
      axios.get(
        "get_all_mcorp_cron_status?ClientCountryId=" + CronStatusClientCountryId)
        .then((response) => {
          setCronStatusData(response.data.data);
        })
        .catch(() => {
        });
    }
  }, [Count, CronStatusClientId, CronStatusClientCountryId])


  useEffect(() => {
    const handle = setInterval(() => {
      setCount(Count + 1);

    }, 10000);

    return () => {
      clearInterval(handle);
    };
  }, [Count]);

  useEffect(() => {
    const handle = setInterval(() => {
      setSiteCount(SiteCount + 1);
    }, 60000);

    return () => {
      clearInterval(handle);
    };
  }, [SiteCount]);

  // GET ALL BASE STATION DOWN
  useEffect(() => {
    axios
      .get(
        "get_all_basestation_down_alerts?ClientId=" + LoginClientId + "&ClientCountryId=" + LoginClientCountryId)
      .then((response) => {

        if ((localStorage.getItem("LastIdOfBSDownAlert")) && (parseInt(localStorage.getItem("LastIdOfBSDownAlert")) !== 0) && (response.data.data.length > 0)) {
          var AlertModuleDataTemp = response.data.data;

          var Count = 0;
          for (let i = 0; i < AlertModuleDataTemp.length; i++) {

            // THIS IS FOR SHOW ALERT NOTIFICATION TOAST
            if (AlertModuleDataTemp[i].Id > localStorage.getItem("BSDownAlertDataId")) {
              var Notification = "Number of " + AlertModuleDataTemp[i].NoOfBasestation + " basestation are down for " + AlertModuleDataTemp[i].IncidentCode + " in " + AlertModuleDataTemp[i].Time + " mins."
              toast.error(Notification)
            }
            // END

            // THIS IS FOR SHOW ALERT BADGE COUNT
            if (AlertModuleDataTemp[i].Id > localStorage.getItem("LastIdOfBSDownAlert")) {
              Count = Count + 1;
            }
            // END
          }

          setAlertBadgeCount(Count);
        }

        setAlertModuleData(response.data.data);

        if (response.data.data.length > 0 && localStorage.getItem("LastIdOfBSDownAlert") === undefined) {
          localStorage.setItem("LastIdOfBSDownAlert", response.data.data[0].Id)
        }

        // THIS IS FOR SHOW ALERT NOTIFICATION TOAST
        if (response.data.data.length > 0) {
          localStorage.setItem("BSDownAlertDataId", JSON.stringify(response.data.data[0].Id))
        }

      })
      .catch(() => {
      });
  }, [Count, LoginClientId, LoginClientCountryId])

  // GET ALL IMPACTED DEVICE ALRTS
  useEffect(() => {
    axios
      .get(
        "get_all_impacted_device_alerts?ClientId=" + LoginClientId + "&ClientCountryId=" + LoginClientCountryId)
      .then((response) => {
        setImpactedDeviceDownAlertData(response.data.data);
      })
      .catch(() => {
      });

  }, [Count, LoginClientId, LoginClientCountryId])

  // GET ALL BASE STATION SPECFIC
  useEffect(() => {
    axios
      .get(
        "get_all_bs_specific_alerts?ClientId=" + LoginClientId + "&ClientCountryId=" + LoginClientCountryId)
      .then((response) => {
        setBaseStationSpecificData(response.data.data);
      })
      .catch(() => {
      });

  }, [Count, LoginClientId, LoginClientCountryId])

  useEffect(() => {
    if ((UserGroup.Id === 1 || UserGroup.Id === 2) && CountrywiseBaseStationData.length === 0) {
      setSmallLoader(true)
      axios
        .get("get_clientwise_data")
        .then((response) => {
          setClientWiseBaseStationData(response.data.data.DashboardData);
          setClientTicketFlag(response.data.data.TicketsModuleAccess)
          setSmallLoader(false)
        })
        .catch(() => {
          setSmallLoader(false)
        });
    }
  }, [UserGroup.Id, CountrywiseBaseStationData, Count]);

  useEffect(() => {
    if (ClientId > 0 && SitewiseBaseStationData.length === 0 && CountrywiseBaseStationData.length === 0) {
      setSmallLoader(true)
      axios
        .get("get_countrywise_data?ClientId=" + ClientId)
        .then((response) => {
          setCountrywiseBaseStationData(response.data.data.DashboardData);
          setCountryTicketFlag(response.data.data.TicketsModuleAccess)
          if (ClientName === "") {
            setClientName(response.data.data.DashboardData[0].ClientName);
          }
          setSmallLoader(false)
        })
        .catch(() => {
          setSmallLoader(true)
        });
    }
  }, [ClientId, UserGroup.Id, SitewiseBaseStationData, Count, ClientName, CountrywiseBaseStationData]);

  useEffect(() => {
    if (ClientId > 0 && ClientCountryId > 0 && CountrywiseBaseStationData.length !== 0) {

      setLoader(true);
      const ShowAllTemp = !ShowAll ? 0 : 1
      axios
        .get(
          "sitestatuswise_basestation_count?ClientId=" +
          ClientId +
          "&ClientCountryId=" +
          ClientCountryId +
          "&ShowAll=" +
          ShowAllTemp +
          "&Region=" +
          Region
        )
        .then((response) => {
          setSitewiseBaseStationData(response.data.data.SiteStatusWiseCounts);
          setTotalCounts(response.data.data.TotalCounts)
          setTicketsModuleAccess(response.data.data.TicketsModuleAccess)
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [ClientId, ClientCountryId, ShowAll, SiteCount, CountrywiseBaseStationData, Region, RegionList]);

  useEffect(() => {
    if (SelectedBaseStation !== '' && SitewiseBaseStationData.length > 0) {
      setBasestations(SitewiseBaseStationData[SelectedBaseStation].Basestations)
    } else {
      setBasestations([])
    }
  }, [SitewiseBaseStationData, SelectedBaseStation]);

  useEffect(() => {
    if (SitewiseBaseStationData.length !== 0 && SelectedBaseStation === "" && SelectedState !== "") {
      var arr = [];
      for (var i = 0; i < SitewiseBaseStationData.length; i++) {
        if (SitewiseBaseStationData[i].Basestations.length > 0) {
          for (var j = 0; j < SitewiseBaseStationData[i].Basestations.length; j++) {
            arr.push(SitewiseBaseStationData[i].Basestations[j])
          }
        }
      }
      setBasestations(arr)
    }

  }, [SitewiseBaseStationData, SelectedState, SelectedBaseStation])

  useEffect(() => {
    if (TotalBaseStationFlag && SelectedBaseStation === "" && SelectedState === "") {
      var arr = [];
      for (var i = 0; i < SitewiseBaseStationData.length; i++) {
        if (SitewiseBaseStationData[i].Basestations.length > 0) {
          for (var j = 0; j < SitewiseBaseStationData[i].Basestations.length; j++) {
            arr.push(SitewiseBaseStationData[i].Basestations[j])
          }
        }
      }
      setBasestations(arr)
    }
  }, [TotalBaseStationFlag, SelectedBaseStation, SelectedState, SitewiseBaseStationData])

  const getBasestationsTestData = (BaseStationId, ClientCountryId, Name) => {
    setLoader(true);
    axios
      .get("basestations_tests?BaseStationId=" + BaseStationId + "&ClientCountryId=" + ClientCountryId + "&ClientId=" + ClientId)
      .then((response) => {
        setBasestationsTestData(response.data.data);
        setBasestationsTestName(Name);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  const BaseExportData = (Basestations) => {
    let ExportData = []

    for (let i = 0; i < Basestations.length; i++) {
      let StateValue = BitComSelectedState === "BitState" ? Basestations[i].StateValue : Basestations[i].CommStateValue
      if (SelectedState !== '') {
        if (SelectedState === StateValue) {
          ExportData.push(Basestations[i])
        }
      } else {
        ExportData.push(Basestations[i])
      }
    }

    setLoader(true);
    axios
      .post("export_dashboard_basestations", {
        ExportData: ExportData
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  useEffect(() => {
    setSelectedBaseStation('');

  }, [ShowAll])

  useEffect(() => {
    if (SelectedBaseStation !== "" || SelectedState !== "" || TotalBaseStationFlag) {
      setSearchBaseStation("")
    }
  }, [SelectedState, SelectedBaseStation, TotalBaseStationFlag])

  return (
    <>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}

      {
        CronStatusClientId !== "" ? <CronStatus setCronStatusClientId={setCronStatusClientId} CronStatusData={CronStatusData} AllCountries={AllCountries} setCronStatusClientCountryId={setCronStatusClientCountryId} CronStatusClientCountryId={CronStatusClientCountryId} setSelectedCronStatusPage={setSelectedCronStatusPage} SelectedCronStatusPage={SelectedCronStatusPage} /> : null
      }

      {BsDownAlertFlag ? <BsDownAlert setAlertBadgeCount={setAlertBadgeCount} AlertModuleData={AlertModuleData} setBsDownAlertFlag={setBsDownAlertFlag} BsDownAlertFlag={BsDownAlertFlag} /> : null}

      {ImpactedDeviceDownAlertFlag ? <ImpactedDeviceDownAlert ImpactedDeviceDownAlertData={ImpactedDeviceDownAlertData} setImpactedDeviceDownAlertFlag={setImpactedDeviceDownAlertFlag} ImpactedDeviceDownAlertFlag={ImpactedDeviceDownAlertFlag} /> : null}

      {BaseStationSpecificFlag ? <BaseStationSpecificAlert BaseStationSpecificData={BaseStationSpecificData} setBaseStationSpecificFlag={setBaseStationSpecificFlag} BaseStationSpecificFlag={BaseStationSpecificFlag} /> : null}

      {
        SmallLoader ? <div style={{ position: 'fixed', bottom: '40px', right: '40px', width: '3rem', height: '3rem' }} className="spinner-border text-primary" ><span className="visually-hidden">Loading...</span></div> : null
      }

      {BasestationsTestData.length > 0 ? <BasestationDetails BasestationsTestName={BasestationsTestName} BasestationsTestData={BasestationsTestData} setBasestationsTestData={setBasestationsTestData} /> : null}

      {SiteId !== "" ? <OpenTickets SiteName={SiteName} setSiteName={setSiteName} ClientCountryId={ClientCountryId} setSiteId={setSiteId} SiteId={SiteId} Identify={Identify} /> : null}

      {/* CLIENT */}
      {CountrywiseBaseStationData.length === 0 && ClientWiseBaseStationData.length > 0 ? (
        <ClientPage ClientSearch={ClientSearch} setClientSearch={setClientSearch} BsDownAlertFlag={BsDownAlertFlag} setBsDownAlertFlag={setBsDownAlertFlag} AlertBadgeCount={AlertBadgeCount} ImpactedDeviceDownAlertFlag={ImpactedDeviceDownAlertFlag} setImpactedDeviceDownAlertFlag={setImpactedDeviceDownAlertFlag} BaseStationSpecificFlag={BaseStationSpecificFlag} setBaseStationSpecificFlag={setBaseStationSpecificFlag} ClientWiseBaseStationData={ClientWiseBaseStationData} ClientId={ClientId} UserGroup={UserGroup} setCronStatusClientId={setCronStatusClientId} setSelectedCronStatusPage={setSelectedCronStatusPage} setClientId={setClientId} setClientCountryId={setClientCountryId} setClientName={setClientName} ClientTicketFlag={ClientTicketFlag} LoginClientId={LoginClientId} LoginClientCountryId={LoginClientCountryId} />
      ) : null
      }

      {/* COUNTRY */}
      {
        SitewiseBaseStationData.length === 0 && CountrywiseBaseStationData.length > 0 ? (
          <CountryPage UserGroup={UserGroup} setCountrywiseBaseStationData={setCountrywiseBaseStationData} setClientId={setClientId} ClientName={ClientName} setCountrySearch={setCountrySearch} LoginClientId={LoginClientId} LoginClientCountryId={LoginClientCountryId} BsDownAlertFlag={BsDownAlertFlag} setBsDownAlertFlag={setBsDownAlertFlag} AlertBadgeCount={AlertBadgeCount} CountrywiseBaseStationData={CountrywiseBaseStationData} ClientCountryId={ClientCountryId} setCronStatusClientId={setCronStatusClientId} setCronStatusClientCountryId={setCronStatusClientCountryId} setSelectedCronStatusPage={setSelectedCronStatusPage} setClientCountryId={setClientCountryId} setCountryName={setCountryName} setBitComSelectedState={setBitComSelectedState} CountryTicketFlag={CountryTicketFlag} CountrySearch={CountrySearch} setImpactedDeviceDownAlertFlag={setImpactedDeviceDownAlertFlag} setBaseStationSpecificFlag={setBaseStationSpecificFlag} />
        ) : null
      }

      {
        SitewiseBaseStationData.length > 0 ? (
          <div className="row">
            <div className="col-md-12">
              <div className="row mb-3 d-flex align-items-center">
                <div className="col-md-6">
                  {/* <h5>Site Status Wise Base-Stations</h5> */}
                  <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                    <div className="breadcrumb-title pe-3">
                      Site Status Base-Stations
                    </div>
                    {(UserGroup.Id === 1 || UserGroup.Id === 2 || UserGroup.Id === 3 || UserGroup.Id === 4) ?
                      <div className="ps-3">
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item">
                              {
                                UserGroup.Id === 1 || UserGroup.Id === 2 ?
                                  <Link to="">
                                    <i
                                      className="bx bx-home-alt"
                                      onClick={() => {
                                        if (UserGroup.Id === 1 || UserGroup.Id === 2) {
                                          setCountrywiseBaseStationData([]);
                                          setClientId("");
                                          setSelectedBaseStation('')
                                          setSelectedState("")
                                        }
                                        setSitewiseBaseStationData([]);
                                        setClientCountryId("");
                                      }}
                                    ></i>
                                  </Link> : <i
                                    className="bx bx-home-alt"></i>
                              }
                            </li>
                            {ClientName ?
                              <li
                                style={{ cursor: 'pointer' }}
                                className="breadcrumb-item"
                                onClick={() => {
                                  setSitewiseBaseStationData([]);
                                  setClientCountryId("");
                                  setSelectedBaseStation('')
                                  setSelectedState("")
                                }}
                              >
                                {ClientName}
                              </li> : null}
                            <li className="breadcrumb-item active">
                              {CountryName}
                            </li>
                          </ol>
                        </nav>
                      </div> : null}
                  </div>
                </div>
                <div className="col-md-6 text-right d-flex align-items-center justify-content-end gap-3" >

                  <select
                    className="form-select"
                    onChange={(e) => {
                      setRegion(e.target.value)
                    }}
                    value={Region} style={{ width: "20%" }}
                  >
                    <option value=""> Select Region</option>
                    {
                      RegionList.map((item, index) => (
                        <option key={index} value={item}> {item} </option>
                      ))
                    }
                  </select>
                  {(Region) ?

                    <i className="bx bx-eraser search-icon box-icon-style" style={{ fontSize: "25px" }} onClick={() => {
                      setRegion("");
                    }} />
                    : null}

                  {UserGroup.Id < 3 ?
                    <i
                      className="bx bx-cog box-icon-style"
                      style={{ fontSize: "25px" }}
                      onClick={() => {
                        setCronStatusClientId(ClientId);
                        setCronStatusClientCountryId(ClientCountryId);
                        setSelectedCronStatusPage("country")
                      }}
                    ></i> : null}

                  <button title="All Statuses" className="btn btn-primary" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }} onClick={() => setShowAll(!ShowAll)}>{ShowAll ? "Show Enabled" : "Show All"}</button>
                  <button title="All Statuses" className={BitComSelectedState === "BitState" ? "btn btn-primary" : "btn btn-secondary"} style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }} onClick={() => setBitComSelectedState("BitState")}> {BitComSelectedState === "BitState" ? <u> BIT State </u> : "BIT State"}</button>
                  <button title="All Statuses" className={BitComSelectedState === "ComState" ? "btn btn-primary" : "btn btn-secondary"} style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }} onClick={() => setBitComSelectedState("ComState")}>{BitComSelectedState === "ComState" ? <u> Comm State </u> : "Comm State"}</button>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search status here..."
                    value={StatusSearch}
                    onChange={(e) => setStatusSearch(e.target.value)}
                    style={{ display: 'none' }}
                  />

                  {parseInt(UserGroup.Id) === 4 ? <span className="d-flex"> <div className="position-badge text-center" onClick={() => {
                    setBsDownAlertFlag(!BsDownAlertFlag);
                  }}>
                    {AlertBadgeCount ? <div className="badge green">{AlertBadgeCount}</div>
                      : null}

                    <i className="bx bx-bell icon box-icon-style" style={{ fontSize: "33px" }} ></i>
                  </div>
                    <i
                      className="bx bx-notification box-icon-style"
                      style={{ fontSize: "30px" }}
                      onClick={() => {
                        setImpactedDeviceDownAlertFlag(!ImpactedDeviceDownAlertFlag);
                      }}
                    ></i>

                    <i
                      className="bx bx-bell-plus mx-2 box-icon-style"
                      style={{ fontSize: "30px" }}
                      onClick={() => {
                        setBaseStationSpecificFlag(!BaseStationSpecificFlag);
                      }}
                    ></i>

                  </span> : null}

                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="row">
                    <div className="col-md-6 dashboard-site-status-base-stations">
                      <input type="text" className="form-control" placeholder="Search base station here..." value={SearchBaseStation} onChange={(e) => setSearchBaseStation(e.target.value)} />
                      <Map setShowAll={setShowAll} ShowAll={ShowAll} setSelectedBaseStation={setSelectedBaseStation} SearchBaseStation={SearchBaseStation} SitewiseBaseStationData={SitewiseBaseStationData} SelectedBaseStation={SelectedBaseStation} SelectedState={SelectedState} getBasestationsTestData={getBasestationsTestData} BitComSelectedState={BitComSelectedState} ClientCountryId={ClientCountryId} TicketsModuleAccess={TicketsModuleAccess} />
                    </div>
                    <div className="col-md-6 dashboard-site-status-base-stations" style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
                      <div className="row mb-4"
                      >
                        <table className="table mb-0 table-striped dashboard-stations">
                          <thead>
                            <tr>
                              <th className="text-left" style={{ width: '150px' }}>Status</th>
                              <th>Total</th>
                              <th className="ok-color">OK</th>
                              <th className="ko-color">KO</th>
                              <th className="no-color">NO</th>
                              <th className="warn-color">WARN</th>
                            </tr>
                          </thead>
                          <tbody>
                            {SitewiseBaseStationData.map((item, index) => {
                              item.SiteStatusValue = item.SiteStatusValue
                                ? item.SiteStatusValue
                                : "-";
                              return item.SiteStatusValue.toLowerCase().includes(
                                StatusSearch.trim().toLowerCase()
                              ) ? (
                                <tr
                                  key={index}
                                >
                                  <td
                                    style={(SelectedBaseStation === index) ? { cursor: 'pointer', fontWeight: 'bold', fontSize: '16px' } : { cursor: 'pointer' }}
                                    onClick={() => {
                                      if (SelectedBaseStation === index) {
                                        setSelectedBaseStation('')
                                      } else {
                                        setTotalBaseStationFlag(false);
                                        setSelectedBaseStation(index)
                                        setSelectedState("")
                                      }
                                    }}
                                    className="text-left">
                                    {item.SiteStatusValue}
                                  </td>

                                  <td
                                    className={(SelectedState === "" && SelectedBaseStation === index) ? "ok-color-active" : "cursor-pointer"}
                                    onClick={() => {
                                      if (SelectedState === "" && SelectedBaseStation === index) {
                                        setSelectedBaseStation("")
                                        setSelectedState("")
                                      } else {
                                        setTotalBaseStationFlag(false);
                                        setSelectedState("")
                                        setSelectedBaseStation(index)
                                      }
                                    }}
                                  >{item.TotalCount}</td>

                                  <td
                                    className={(SelectedState === "OK" && SelectedBaseStation === index) ? "ok-color-active" : "cursor-pointer"}
                                    onClick={() => {
                                      if (SelectedState === "OK" && SelectedBaseStation === index) {
                                        setSelectedBaseStation("")
                                        setSelectedState("")
                                      } else {
                                        setTotalBaseStationFlag(false);
                                        setSelectedState("OK")
                                        setSelectedBaseStation(index)
                                      }
                                    }}>
                                    {BitComSelectedState === "BitState" ? item.OKStateCount : item.OKCommStateCount}
                                  </td>

                                  <td
                                    className={(SelectedState === "KO" && SelectedBaseStation === index) ? "ko-color-active" : "cursor-pointer"}
                                    onClick={() => {
                                      if (SelectedState === "KO" && SelectedBaseStation === index) {
                                        setSelectedBaseStation("")
                                        setSelectedState("")
                                      } else {
                                        setTotalBaseStationFlag(false);
                                        setSelectedBaseStation(index)
                                        setSelectedState("KO")
                                      }
                                    }}
                                  >
                                    {BitComSelectedState === "BitState" ? item.KOStateCount : item.KOCommStateCount}
                                  </td>
                                  <td
                                    className={(SelectedState === "NO" && SelectedBaseStation === index) ? "no-color-active" : "cursor-pointer"}
                                    onClick={() => {
                                      if (SelectedState === "NO" && SelectedBaseStation === index) {
                                        setSelectedBaseStation("")
                                        setSelectedState("")
                                      } else {
                                        setTotalBaseStationFlag(false);
                                        setSelectedBaseStation(index)
                                        setSelectedState("NO")
                                      }
                                    }}
                                  >
                                    {BitComSelectedState === "BitState" ? item.NOStateCount : item.NOCommStateCount}
                                  </td>
                                  <td
                                    className={(SelectedState === "WARN" && SelectedBaseStation === index) ? "warn-color-active" : "cursor-pointer"}
                                    onClick={() => {
                                      if (SelectedState === "WARN" && SelectedBaseStation === index) {
                                        setSelectedBaseStation("")
                                        setSelectedState("")
                                      } else {
                                        setTotalBaseStationFlag(false);
                                        setSelectedBaseStation(index)
                                        setSelectedState("WARN")
                                      }
                                    }}
                                  >
                                    {BitComSelectedState === "BitState" ? item.WARNStateCount : item.WARNCommStateCount}
                                  </td>
                                </tr>
                              ) : null;

                            })}
                            <tr style={{ fontWeight: "bold" }}>
                              <td className="text-left" >Total</td>
                              <td className={(TotalBaseStationFlag) ? "ok-color-active" : "cursor-pointer"} onClick={(e) => {
                                if (TotalBaseStationFlag) {
                                  setTotalBaseStationFlag(false);
                                } else {
                                  setTotalBaseStationFlag(true);
                                  setSelectedBaseStation("")
                                  setSelectedState("")
                                }
                              }}
                              >{TotalCounts['TotalCount']} </td>
                              <td className={(SelectedState === "OK" && SelectedBaseStation === "") ? "ok-color-active" : "cursor-pointer"} onClick={() => {
                                if (SelectedState === "OK") {
                                  setBasestations([])
                                  setSelectedState("")
                                } else {
                                  setTotalBaseStationFlag(false);
                                  setSelectedBaseStation("")
                                  setSelectedState("OK")
                                }
                              }}
                              >{BitComSelectedState === "BitState" ? TotalCounts['OKStateCount'] : TotalCounts['OKCommStateCount']}</td>
                              <td className={(SelectedState === "KO" && SelectedBaseStation === "") ? "ko-color-active" : "cursor-pointer"} onClick={() => {
                                if (SelectedState === "KO") {
                                  setBasestations([])
                                  setSelectedState("")
                                } else {
                                  setTotalBaseStationFlag(false);
                                  setSelectedBaseStation("")
                                  setSelectedState("KO")
                                }
                              }}
                              >{BitComSelectedState === "BitState" ? TotalCounts['KOStateCount'] : TotalCounts['KOCommStateCount']}</td>
                              <td className={(SelectedState === "NO" && SelectedBaseStation === "") ? "no-color-active" : "cursor-pointer"} onClick={() => {
                                if (SelectedState === "NO") {
                                  setBasestations([])
                                  setSelectedState("")
                                } else {
                                  setTotalBaseStationFlag(false);
                                  setSelectedBaseStation("")
                                  setSelectedState("NO")
                                }
                              }}
                              >{BitComSelectedState === "BitState" ? TotalCounts['NOStateCount'] : TotalCounts['NOCommStateCount']}</td>
                              <td className={(SelectedState === "WARN" && SelectedBaseStation === "") ? "warn-color-active" : "cursor-pointer"} onClick={() => {
                                if (SelectedState === "WARN") {
                                  setBasestations([])
                                  setSelectedState("")
                                } else {
                                  setTotalBaseStationFlag(false);
                                  setSelectedBaseStation("")
                                  setSelectedState("WARN")
                                }
                              }}
                              >{BitComSelectedState === "BitState" ? TotalCounts['WARNStateCount'] : TotalCounts['WARNCommStateCount']}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      {
                        Basestations.length > 0 ?
                          <>

                            <div className="col-md-12">
                              <h5 style={{ textAlign: 'left', margin: 0, float: 'left' }}>Base Stations</h5>
                              <button title="Export" className="btn btn-primary" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px', float: 'right' }} onClick={() => BaseExportData(Basestations)}>Export</button>
                            </div>
                            <div className="row mt-3" style={{ overflow: 'auto' }}>
                              <table className="table mb-0 table-striped dashboard-stations">
                                <thead>
                                  <tr>
                                    <th>Basestation Id</th>
                                    <th className="text-left">Site Name</th>
                                    <th className="text-left">Site Host</th>
                                    <th>Bit Status</th>
                                    <th>Comm Status</th>
                                    <th>Status of Tests</th>
                                    {TicketsModuleAccess ? SelectedState === "KO" ? <th>
                                      Open Tickets
                                    </th> : null : null}
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    Basestations.map((item, index) => {
                                      const StateValue = BitComSelectedState === "BitState" ? item.StateValue : item.CommStateValue
                                      return (
                                        (SelectedState === StateValue) ?
                                          <tr key={index}>
                                            <td>{item.BaseStationId}</td>
                                            <td className="text-left" style={{ wordWrap: 'anywhere' }}>{item.SiteName}</td>
                                            <td className="text-left">{item.SiteHostName}</td>
                                            <td>{item.StateValue}</td>
                                            <td>{item.CommStateValue}</td>
                                            <td>
                                              <button title="Show Test" className="btn btn-primary" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }} onClick={() => getBasestationsTestData(item.BaseStationId, item.ClientCountryId, item.Name)}>
                                                View
                                              </button>
                                            </td>
                                            {TicketsModuleAccess ? SelectedState === "KO" ? <td>
                                              <button title="Show Open Tickets" className="btn btn-primary" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }} onClick={(e) => { setSiteId(item.SiteId); setSiteName(item.SiteName) }}>
                                                View
                                              </button>
                                            </td> : null : null}
                                          </tr>
                                          : (!SelectedState) ?
                                            <tr key={index}>
                                              <td>{item.BaseStationId}</td>
                                              <td className="text-left" style={{ wordWrap: 'anywhere' }}>{item.SiteName}</td>
                                              <td className="text-left">{item.SiteHostName}</td>
                                              <td>{item.StateValue}</td>
                                              <td>{item.CommStateValue}</td>
                                              <td>
                                                <button title="Show Test" className="btn btn-primary" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }} onClick={() => getBasestationsTestData(item.BaseStationId, item.ClientCountryId, item.Name)}>
                                                  View
                                                </button>
                                              </td>
                                              {TicketsModuleAccess ? SelectedState === "KO" ? <td>
                                                <button title="Show Open Tickets" className="btn btn-primary" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }} onClick={(e) => { setSiteId(item.SiteId); setSiteName(item.SiteName) }}>
                                                  View
                                                </button>
                                              </td> : null : null}
                                            </tr>
                                            : null
                                      )
                                    })
                                  }
                                </tbody>
                              </table>
                            </div>
                          </> : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null
      }

      <footer className="page-footer">
        <div className="row">
          <div className="col-md-4">Basestation Last Sync: 22-Nov-2022 12:11:12</div>
          <div className="col-md-4">Test Last Sync: 22-Nov-2022 12:11:12</div>
          <div className="col-md-4">Device Last Sync: 22-Nov-2022 12:11:12</div>
        </div>
      </footer>

    </>
  );
};

export default Home;
