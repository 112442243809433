import React, { useState, useEffect, useRef } from 'react'
import axios from "../../axios";
import Loader from "../../Components/Loader";
import TimeZone from "../../TimeZone";

const Log = (props) => {
    const [loader, setLoader] = useState(false);
    const [TicketHistory, setTicketHistory] = useState([]);
    const DivRef = useRef(null);

    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setTicketLogId({})
        }
    }

    useEffect(() => {
        if (props.TicketLogId.TicketId) {
            setLoader(true);
            axios
                .get(
                    "get_meraki_ticket_history?ClientCountryId=" + props.CountryID + "&TicketId=" + props.TicketLogId.TicketId)
                .then((response) => {
                    setTicketHistory(response.data.data);
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }

    }, [props.TicketLogId, props.CountryID])

    return (
        <>
            {loader ? <Loader /> : null}
            <div
                className='popup-background'
                onClick={(e) => {
                    handleCloseClick(e)
                }}
            >
                <div
                    className="card"
                    style={{
                        maxWidth: "60%",
                        height: "fit-content",
                        margin: "auto",
                        textAlign: "center",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",

                    }}
                    ref={DivRef}

                >
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-11 text-center mb-3">
                                <h5>Meraki Ticket Logs ({props.TicketLogId.TicketId}) : {props.TicketLogId.SiteName}</h5>
                            </div>

                            <div className="col-md-1 text-right">
                                <button className="btn btn-secondary"
                                    onClick={() => {
                                        props.setTicketLogId({})
                                    }}
                                >x</button>
                            </div>

                            <div className="col-md-12 mb-3  horizontal-scroll">
                                <table className="table mb-0 table-striped ticket-table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "70px" }}>#</th>
                                            <th className='text-left'>DateTime</th>
                                            <th className='text-left'>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {TicketHistory.length > 0 ?
                                            TicketHistory.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td className='text-left'>{(item.ActionTime) ? <TimeZone timestamp={item.ActionTime} /> : "-"}</td>
                                                        <td className='text-left'>{item.Description}</td>
                                                    </tr>
                                                );
                                            }) :
                                            <tr>
                                                <td colSpan={3}> No Record Found</td>
                                            </tr>
                                        }

                                        {/* {RemoteFixData.CreatedTimestamp !== null ? <tr>
                                            <td>
                                                {TicketHistory.length + 1}
                                            </td>
                                            <td>{(RemoteFixData.CreatedTimestamp) ? <TimeZone timestamp={RemoteFixData.CreatedTimestamp} /> : "-"}</td>
                                            <td>{parseInt(RemoteFixData.RemoteRestartSuccess) === 0 ? "Remote Fix : Success" : "Remote Fix Error : " + RemoteFixData.RemoteRestartResponse}</td>
                                        </tr> : null} */}
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-md-12 mb-3'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Log