import React, { useState, useRef } from 'react';
import Loader from '../../Components/Loader';
import axios from '../../axios';
import { toast } from 'react-toastify';

const TicketDescription = (props) => {
    const [loader, setLoader] = useState(false);
    const [Description, setDescription] = useState("");
    const [DescriptionError, setDescriptionError] = useState("");
    const DivRef = useRef(null);

    const handleSubmit = () => {
        setDescriptionError("");
        if (Description.trim() === "") {
            setDescriptionError("Please add ticket description.");
            return;
        }

        setLoader(true);
        if (props.TicketDescriptionData.TicketStatus !== "" && props.CountryID !== "") {
            const payload = {
                ClientCountryId: props.CountryID,
                TicketId: props.TicketDescriptionData.TicketId,
                Description: Description,
                TicketStatus: props.TicketDescriptionData.TicketStatus,
            };

            axios
                .put("update_ticket_histroy/" + props.TicketDescriptionData.TicketId, payload)
                .then((response) => {
                    toast.success(response.data.status.message);
                    setLoader(false);
                    props.setTicketDescriptionData({});
                })
                .catch((error) => {
                    setLoader(false);
                    props.setTicketDescriptionData({});
                });
        }

    };

    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setTicketDescriptionData({});
        }
    };

    return (
        <>
            {loader && <Loader />}
            <div className='popup-background' onClick={handleCloseClick}>
                <div
                    className="card"
                    style={{ maxWidth: "25%", height: "fit-content", margin: "auto", boxShadow: "0px 0px 5px 0px #5c5c5c" }}
                    ref={DivRef}
                >
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-10 mb-3" style={{ textAlign: "center" }}>
                                <h5>Add Ticket ( {props.TicketDescriptionData.TicketId} ) Description</h5>
                            </div>
                            <div className="col-md-2 text-right">
                                <button className="btn btn-secondary" onClick={() => props.setTicketDescriptionData({})}>x</button>
                            </div>

                            <div className="col-md-12 mb-3">
                                <textarea
                                    placeholder="Add Description"
                                    className="form-control"
                                    value={Description}
                                    rows="4"
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                        setDescriptionError("");
                                    }}
                                />
                                <p className='my-2' style={{ color: "red" }}>{DescriptionError}</p>
                            </div>

                            <div className='col-md-12 mb-3 row'>
                                <div className='col-md-12' style={{ textAlign: "center" }}>
                                    <button
                                        className="btn btn-primary"
                                        style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }}
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TicketDescription;
